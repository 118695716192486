/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Result.css";
import { useAppContext } from "../../AppContext";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";
import { ToastContainer } from "react-toastify";
import { FaCheck, FaArrowLeft } from "react-icons/fa";
import Modal from "./Modal";

// Import GIFs locally instead of fetching from the server
import gifSavingResult from "../../assets/gifs/result-saving_result.gif";
import gifDone from "../../assets/gifs/result-done.gif";
import gifFailed from "../../assets/gifs/result-failed.gif";
import gifFaceFailed from "../../assets/gifs/result-face_failed.gif";

const Result = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { userId, eventName } = useAppContext();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const finalImage = localStorage.getItem("finalImage");

  const [gifUrl, setGifUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // This function replaces the previous server request for GIFs
  const getGifUrl = (gifName) => {
    switch (gifName) {
      case "saving_result":
        return gifSavingResult;
      case "done":
        return gifDone;
      case "failed":
        return gifFailed;
      case "face_failed":
        return gifFaceFailed;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (finalImage) {
      const img = new Image();
      img.src = finalImage;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const maxWidth = window.innerWidth * 0.85;
        const maxHeight = window.innerHeight * 0.7;
        let width, height;
        if (maxWidth / img.width <= maxHeight / img.height) {
          if (maxWidth < img.width) {
            width = maxWidth;
            height = maxWidth / aspectRatio;
          }
        } else if (maxWidth / img.width > maxHeight / img.height) {
          if (maxHeight < img.height) {
            height = maxHeight;
            width = maxHeight * aspectRatio;
          }
        } else {
          width = img.width;
          height = img.height;
        }
        setImageDimensions({ width, height });
        hideLoading();
      };
    }
  }, [finalImage]);

  const handleApprove = async (download = false) => {
    setGifUrl(getGifUrl("saving_result"));

    try {
      const selectedFaces =
        JSON.parse(sessionStorage.getItem("selectedFaces")) || [];
      if (finalImage) {
        const response = await fetch(
          `${serverUrl}/approve?event_name=${eventName}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              image: finalImage,
              selected_faces: selectedFaces,
              UserId: userId,
              event_name: eventName,
            }),
          }
        );

        if (response.ok) {
          setGifUrl(getGifUrl("done"));
          if (download) {
            await handleDownload();
          }
          setTimeout(() => {
            localStorage.removeItem("finalImage");
            navigate(`/${eventName}`, { state: { imageUrl: null } });
          }, 4000);
        } else {
          const errorGifName =
            response.status === 404 ? "face_failed" : "failed";
          setGifUrl(getGifUrl(errorGifName));
          setTimeout(() => {
            navigate(`/${eventName}/face-gallery`, {
              state: { imageUrl: null },
            });
            hideLoading();
            console.error("Failed to approve the image");
          }, 30000);
        }
      } else {
        setGifUrl(getGifUrl("failed"));
        setTimeout(() => {
          navigate(`/${eventName}`, { state: { imageUrl: null } });
          hideLoading();
          console.error("No final image found in local storage");
        }, 30000);
      }
    } catch (error) {
      setGifUrl(getGifUrl("failed"));
      setTimeout(() => {
        navigate(`/${eventName}`, { state: { imageUrl: null } });
        hideLoading();
        console.error("Error approving the image:", error);
      }, 30000);
    }
  };

  const handleBack = () => {
    showLoading();
    navigate(`/${eventName}/edit-your-character`, {
      state: { imageUrl: null },
    });
  };

  const handleDownload = async () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();
    img.src = finalImage;

    img.onload = () => {
      const desiredHeight = 1771; // Change this value to your desired height in pixels
      const aspectRatio = img.width / img.height;
      const desiredWidth = desiredHeight * aspectRatio;

      canvas.width = desiredWidth;
      canvas.height = desiredHeight;

      // Fill canvas with white background
      context.fillStyle = "#FFFFFF";
      context.fillRect(0, 0, canvas.width, canvas.height);

      const offsetX = (canvas.width - desiredWidth) / 2;
      const offsetY = (canvas.height - desiredHeight) / 2;

      context.drawImage(img, offsetX, offsetY, desiredWidth, desiredHeight);

      canvas.toBlob(
        (blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "final-image.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        "image/png",
        1 // Quality set to 1 (maximum quality)
      );
    };
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    handleApprove(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleApprove(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="result-container">
      <ToastContainer />
      <h1 className="result-title rtl">אשרו את בחירתכם</h1>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      {gifUrl && (
        <div className="gif-overlay">
          <img src={gifUrl} alt="Status GIF" className="status-gif" />
        </div>
      )}
      {finalImage ? (
        <img
          src={finalImage}
          alt="Final Result"
          className="result-image"
          style={{
            width: `${imageDimensions.width}px`,
            height: `${imageDimensions.height}px`,
          }}
        />
      ) : (
        <p className="result-image">אין תמונה להצגה</p>
      )}
      <div className="button-container result-button-container">
        <button className="footer-button" onClick={handleBack}>
          <FaArrowLeft size={24} />
        </button>
        <button className="footer-button" onClick={handleOpenModal}>
          <FaCheck size={24} />
        </button>
      </div>
    </div>
  );
};

export default Result;
