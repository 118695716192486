/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Home.css";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../AppContext";
import { v4 as uuidv4 } from "uuid";
import {
  openDatabase,
  saveFaceImageToDB,
  saveBodyImageToDB,
  getLastUpdateTime,
  updateLastUpdateTime,
  getAllKeysFromDB,
  deleteImageFromDB,
  cleanUpOldEntries,
} from "../../IndexedDBUtils";
import { fetchData } from "../../generalUtils";

const FACE_STORE_NAME = "FaceImages";
const BODY_STORE_NAME = "BodyImages";
const FACE_REFRESH_INTERVAL_SECONDS = 25;
const BODY_REFRESH_INTERVAL_SECONDS = 120;

const FACE_TTL = 1 * 1 * 3 * 60 * 1000; // 3 Minutes
const BODY_TTL = 1 * 1 * 6 * 60 * 1000; // 6 Minutes

const Home = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { eventName, setEventName, setUserId } = useAppContext();
  const params = useParams();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [timeLeft, setTimeLeft] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const urlEventName = params.eventName;
  if (urlEventName) {
    setEventName(urlEventName);
    sessionStorage.setItem("event_name", urlEventName);
  }

  const parseDate = (dateStr) => {
    // Assuming the format is "DD/MM/YYYY HH:mm"
    const [datePart, timePart] = dateStr.split(" ");
    const [day, month, year] = datePart.split("/");
    return new Date(`${year}-${month}-${day}T${timePart}:00`);
  };

  const calculateTimeLeft = (t) => {
    const parsedDate = parseDate(t);
    const difference = new Date(parsedDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const fetchAndStoreFaceImages = async () => {
    try {
      const lastUpdateTime = await getLastUpdateTime(FACE_STORE_NAME);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;

      if (timeDifference < FACE_REFRESH_INTERVAL_SECONDS) {
        console.info("Loading face images from IndexedDB...");
        hideLoading();
        return;
      }

      updateLastUpdateTime(FACE_STORE_NAME);
      console.info("Fetching face images from server...");

      const response = await fetch(
        `${serverUrl}/api/faces?event_name=${encodeURIComponent(urlEventName)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch face image paths");
      }

      const imagePaths = await response.json();
      const keysFromDB = await getAllKeysFromDB(FACE_STORE_NAME);

      const newPaths = imagePaths.filter((path) => !keysFromDB.includes(path));
      const pathsToDelete = keysFromDB.filter(
        (path) => !imagePaths.includes(path)
      );

      if (newPaths.length === 0 && pathsToDelete.length === 0) {
        console.info("No new face images found, skipping server fetch.");
        hideLoading();
        await updateLastUpdateTime(FACE_STORE_NAME);
        return;
      }

      const deletePromises = pathsToDelete.map(async (path) => {
        await deleteImageFromDB(FACE_STORE_NAME, path);
        console.info(`Face image deleted from IndexedDB: ${path}`);
      });

      await Promise.all(deletePromises);

      const imagePromises = newPaths.map(async (path) => {
        const response = await fetch(`${serverUrl}/image${path}`);
        if (!response.ok) {
          console.error(`Failed to fetch image for path: ${path}`);
          hideLoading();
          return;
        }

        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64Data = reader.result;
          await saveFaceImageToDB(path, base64Data);
          console.info(`Face image saved to IndexedDB: ${path}`);
        };
      });

      await Promise.all(imagePromises);
      hideLoading();
      await updateLastUpdateTime(FACE_STORE_NAME);
    } catch (error) {
      console.error("Error fetching and storing face images:", error);
      hideLoading();
    }
  };

  const fetchAndStoreBodyImages = async () => {
    try {
      const lastUpdateTime = await getLastUpdateTime(BODY_STORE_NAME);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;

      if (timeDifference < BODY_REFRESH_INTERVAL_SECONDS) {
        console.info("Loading body images from IndexedDB...");
        return;
      }

      updateLastUpdateTime(BODY_STORE_NAME);
      console.info("Fetching body images from server...");

      const response = await fetch(
        `${serverUrl}/api/bodies?event_name=${encodeURIComponent(urlEventName)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch body image paths");
      }

      const imagePaths = await response.json();
      const keysFromDB = await getAllKeysFromDB(BODY_STORE_NAME);

      const newPaths = imagePaths.filter((path) => !keysFromDB.includes(path));
      const pathsToDelete = keysFromDB.filter(
        (path) => !imagePaths.includes(path)
      );

      if (newPaths.length === 0 && pathsToDelete.length === 0) {
        console.info("No new body images found, skipping server fetch.");
        await updateLastUpdateTime(BODY_STORE_NAME);
        return;
      }

      const deletePromises = pathsToDelete.map(async (path) => {
        await deleteImageFromDB(BODY_STORE_NAME, path);
        console.info(`Body image deleted from IndexedDB: ${path}`);
      });

      await Promise.all(deletePromises);

      const imagePromises = newPaths.map(async (path) => {
        const response = await fetch(`${serverUrl}/image${path}`);
        if (!response.ok) {
          console.error(`Failed to fetch image for path: ${path}`);
          return;
        }

        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64Data = reader.result;
          await saveBodyImageToDB(path, base64Data);
          console.info(`Body image saved to IndexedDB: ${path}`);
        };
      });

      await Promise.all(imagePromises);
      await updateLastUpdateTime(BODY_STORE_NAME);
    } catch (error) {
      console.error("Error fetching and storing body images:", error);
      hideLoading();
    }
  };

  useEffect(() => {
    const initializeAndLoadData = async () => {
      showLoading();
      await openDatabase();
      await cleanUpOldEntries(FACE_STORE_NAME, FACE_TTL);

      try {
        const encodedEventName = encodeURIComponent(
          sessionStorage.getItem("event_name")
        );
        const data = await fetchData(
          serverUrl,
          "/api/get-end_event_time",
          encodedEventName
        );
        if (data && data.endTime) {
          setEndTime(data.endTime);
          setTimeLeft(calculateTimeLeft(data.endTime));
        }
      } catch (error) {
        console.error("Error getting event end time:", error);
      }

      sessionStorage.clear();
      localStorage.clear();

      const uuid = uuidv4();
      sessionStorage.setItem("user_id", uuid);
      setUserId(uuid);
      console.info(`user id: ${uuid}`);

      await fetchAndStoreFaceImages();
      hideLoading();
      await cleanUpOldEntries(BODY_STORE_NAME, BODY_TTL);
      await Promise.all([fetchAndStoreBodyImages()]);
    };

    initializeAndLoadData();
  }, [params.eventName, setEventName, serverUrl]);

  useEffect(() => {
    if (endTime) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const selectNumber = (number) => {
    sessionStorage.setItem("number", number);
    showLoading();
    navigate(`/${eventName}/face-gallery`);
  };

  const formatTimeLeft = () => {
    if (!timeLeft) return "00:00:00";
    const days = timeLeft.days > 0 ? `${timeLeft.days} ימים ` : "";
    return `${days}${String(timeLeft.hours).padStart(2, "0")}:${String(
      timeLeft.minutes
    ).padStart(2, "0")}:${String(timeLeft.seconds).padStart(2, "0")}`;
  };

  return (
    <div className="home-container">
      <div className="home-title">
        <img src={process.env.PUBLIC_URL + "/HomeHeader.png"} alt="Title" />
      </div>
      <div className="logo-container">
        <a
          href="https://minime-events.com/attractions/swingies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.PUBLIC_URL + "/logoTrim.png"}
            alt="Logo"
            className="logo-image"
          />
        </a>
      </div>
      <div className="remaining-text rtl">
        נותרו {formatTimeLeft()} לעיצוב סווינגי משלך.
      </div>
      <div className="home-content">
        <h2 className="home-subtitle rtl">כמה אנשים אתם?</h2>
        <div className="button-container">
          <button className="number-button" onClick={() => selectNumber(1)}>
            1
          </button>
          <button className="number-button" onClick={() => selectNumber(2)}>
            2
          </button>
          <button className="number-button" onClick={() => selectNumber(3)}>
            3
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
