/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { fetchEvents, parseEventName } from "./utils";
import EventPopup from "./EventPopup";
import "./AdminPanel.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaSync } from "react-icons/fa";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";

const AdminPanel = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventStatuses, setEventStatuses] = useState({});
  const [herokuDynoType, setHerokuDynoType] = useState("");
  const [herokuDynoQuantity, setHerokuDynoQuantity] = useState("");
  const [redisAvailable, setRedisAvailable] = useState(true);
  const [redisStatus, setRedisStatus] = useState("");
  const [usedMemory, setUsedMemory] = useState(0);
  const [maxMemory, setMaxMemory] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Required Usage");
  const scrollablePanelRef = useRef(null);
  // States for target height in pixels
  const [targetHeight, setTargetHeight] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [resizeError, setResizeError] = useState(null);
  const { showLoading, hideLoading } = useLoading();
  const navigate = useNavigate();

  // Function to handle the input change for target height
  const handleHeightChange = (e) => {
    const value = e.target.value;

    // Validate input to be a number
    if (/^\d*$/.test(value)) {
      setTargetHeight(value);
    }
  };

  // Function to validate the target height on blur (when leaving the input)
  const handleHeightBlur = () => {
    // Convert to a number for validation
    const heightValue = parseInt(targetHeight);

    // If the value is not within the range, reset it or show an alert
    if (heightValue < 200 || heightValue > 4000) {
      alert("Please enter a valid height between 200 and 4000.");
      setTargetHeight(""); // Optionally clear the input
    }
  };

  // Function to send resize request to the server
  const handleResizeImages = () => {
    if (!targetHeight) {
      alert("Please enter a valid height between 200 and 4000.");
      return;
    }

    // Disable button and reset input field
    setIsButtonDisabled(true);
    setTargetHeight(""); // Clear the input field
    setResizeError(null); // Reset any previous error

    // Re-enable button after 10 seconds
    setTimeout(() => setIsButtonDisabled(false), 10000);

    // Send async request without blocking the UI
    fetch(`${serverUrl}/api/resize-bodies-images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ target_height: parseInt(targetHeight) }),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle different error statuses
          return response.text().then((errorText) => {
            if (response.status === 404) {
              setResizeError(
                "The folder for resizing images was not found on the server."
              );
            } else if (response.status === 400) {
              setResizeError(
                "Invalid request. Please ensure the height is within the range."
              );
            } else {
              setResizeError(`Error resizing images: ${errorText}`);
            }
          });
        }
        // Log that the request has been sent successfully
        console.log(`Resize request for ${targetHeight}px height sent.`);
        alert("Image resize request has been started successfully.");
      })
      .catch((error) => {
        setResizeError(
          `Error resizing images: ${error.message || "Unknown error occurred"}`
        );
      });
  };

  // Handle when error occurs to show alert
  useEffect(() => {
    if (resizeError) {
      alert(resizeError);
      console.error(resizeError);
    }
  }, [resizeError]);

  // Function to handle Redis plan change
  const handleDropdownChange = async (event) => {
    const selectedPlan = event.target.value;

    const userConfirmed = window.confirm(
      `Are you sure you want to change to the ${selectedPlan} plan?`
    );

    if (userConfirmed) {
      try {
        const response = await fetch(`${serverUrl}/api/update-redis-plan`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ plan_name: selectedPlan }),
        });

        if (response.ok) {
          const result = await response.json();
          console.log("Plan updated successfully:", result);
          setSelectedOption(selectedPlan);
          event.target.value = selectedPlan;
          showLoading();
          await loadEvents();
          await fetchHerokuPlan();
          hideLoading();
        } else {
          const errorText = await response.text();
          let errorMessage = "An error occurred while updating the plan.";
          try {
            const errorJson = JSON.parse(errorText);
            if (errorJson.error && errorJson.error.message) {
              errorMessage = errorJson.error.message;
            }
          } catch (parseError) {
            console.error("Failed to parse error response:", parseError);
          }
          console.error(
            "Failed to update plan:",
            response.statusText,
            errorText
          );
          alert(`Failed to change Redis plan: ${errorMessage}`);
        }
      } catch (error) {
        console.error("Error updating plan:", error);
        alert(
          `Error updating plan: ${error.message || "Unknown error occurred"}`
        );
      }
    } else {
      event.target.value = selectedOption;
    }
  };

  const [newEvent, setNewEvent] = useState({
    eventName: "",
    startTime: null,
    endTime: null,
    reusableHeads: false,
  });

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const loadData = async () => {
      showLoading();
      console.log("loadEvents!");
      await loadEvents();
      await fetchHerokuPlan();
      hideLoading();
    };

    loadData();

    const intervalId = setInterval(() => {
      if (document.hidden) return;
      loadEvents();
      fetchHerokuPlan();
    }, 55000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (redisAvailable) {
      const memoryMap = {
        25: "mini",
        50: "premium-0",
        100: "premium-1",
        250: "premium-2",
        500: "premium-3",
        1024: "premium-5",
      };
      const defaultOption = memoryMap[maxMemory];
      if (defaultOption) {
        setSelectedOption(defaultOption);
      }
    }
  }, [redisAvailable, maxMemory]);

  const loadEvents = async () => {
    try {
      fetchEvents(
        `${serverUrl}/api/events-managements`,
        async (fetchedEvents) => {
          const parsedEvents = fetchedEvents
            .map((event) => {
              try {
                return parseEventName(event);
              } catch (e) {
                console.error(e.message);
                return null;
              }
            })
            .filter(Boolean)
            .sort((a, b) => new Date(a.date) - new Date(b.date));
          setEvents(parsedEvents);

          const response = await fetch(`${serverUrl}/api/events-status`);
          const statuses = await response.json();
          setEventStatuses(
            Object.values(statuses).reduce((acc, status) => {
              acc[status.event_name] = {
                ...status,
                startTime: status.startTime,
                endTime: status.endTime,
              };
              return acc;
            }, {})
          );
        }
      );
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchHerokuPlan = async () => {
    try {
      const response = await fetch(`${serverUrl}/api/heroku-redis-plan`);
      if (!response.ok) {
        throw new Error("Failed to fetch Heroku dyno type");
      }
      const data = await response.json();
      setHerokuDynoType(data.dyno_type);
      setHerokuDynoQuantity(data.dyno_quantity);

      const usedMemory = parseFloat(data.used_memory_mb) || 0;
      const maxMemory = parseFloat(data.max_memory_mb) || 0;
      setUsedMemory(usedMemory);
      setMaxMemory(maxMemory);

      setRedisAvailable(data.redis_available);
      setRedisStatus(data.redis_status);
    } catch (error) {
      console.error("Error fetching Heroku dyno type:", error);
    }
  };

  if (loading) {
    return <div>Loading.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    if (new Date(newEvent.startTime) >= new Date(newEvent.endTime)) {
      return;
    }
    showLoading();
    try {
      const startTimeFormatted = newEvent.startTime
        .toLocaleString("he-IL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", "");

      const endTimeFormatted = newEvent.endTime
        .toLocaleString("he-IL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", "");
      const response = await fetch(`${serverUrl}/create-event`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_name: newEvent.eventName,
          start_time: startTimeFormatted,
          end_time: endTimeFormatted,
          reusable_heads: newEvent.reusableHeads,
          domain: window.location.origin,
        }),
      });
      if (response.ok) {
        const createdEvent = await response.json();
        setEvents([...events, parseEventName(createdEvent.event_id)]);
        setNewEvent({
          eventName: "",
          startTime: null,
          endTime: null,
          reusableHeads: false,
        });
      } else {
        hideLoading();
        console.error("Error creating event:", response.statusText);
      }
    } catch (error) {
      hideLoading();
      console.error("Error creating event:", error);
    }
    hideLoading();
  };

  const handleEventClick = (event) => {
    try {
      const status = eventStatuses[event.name];
      const startTimeFormatted = status.startTime;
      const endTimeFormatted = status.endTime;
      setSelectedEvent({
        ...event,
        ...status,
        startTimeFormatted,
        endTimeFormatted,
      });
    } catch (e) {
      console.error(`e: ${e}`);
    }
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const isEventReady = (event) => {
    return eventStatuses[event.name]?.ready ?? false;
  };

  const isEventInProgress = (event) => {
    const status = eventStatuses[event.name]?.inProgress;
    switch (status) {
      case "waiting":
        return "upcoming";
      case "running":
        return "ongoing";
      case "pass":
        return "completed";
      default:
        return "";
    }
  };

  const toggleReusableHeads = () => {
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      reusableHeads: !prevEvent.reusableHeads,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "eventName") {
      const filteredValue = value.replace(/[^a-zA-Z0-9&]/g, "");
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [name]: filteredValue,
      }));
    } else {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, field) => {
    if (field === "startTime" && newEvent.endTime && date >= newEvent.endTime) {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
        endTime: null,
      }));
    } else {
      setNewEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
      }));
    }
  };

  const handleRefresh = async () => {
    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/update-events-status`, {
        method: "POST",
      });
      if (response.ok) {
        await loadEvents();
        await fetchHerokuPlan();
      } else {
        hideLoading();
        console.error("Failed to refresh events status");
      }
    } catch (error) {
      hideLoading();
      console.error("Error refreshing events status:", error);
    }
    hideLoading();
  };

  return (
    <div className="admin-panel-container">
      <header className="admin-header">Admin</header>
      <div className="heroku-status-container">
        <div className="heroku-status">
          <p className="dyno-label">Dynos Type:</p>
          <p className="dyno-value">{herokuDynoType}</p>
          <p className="dyno-label">Dynos Count:</p>
          <p className="dyno-value">{herokuDynoQuantity}</p>
          <p className="dyno-label">Memory Usage:</p>
          <p className="dyno-value">{`${usedMemory.toFixed(
            2
          )} / ${maxMemory} MB`}</p>
          <select
            className="dyno-label"
            onChange={handleDropdownChange}
            value={selectedOption}
            disabled={!redisAvailable}
          >
            <option value="mini">mini (25MB)</option>
            <option value="premium-0">premium-0 (50MB)</option>
            <option value="premium-1">premium-1 (100MB)</option>
            <option value="premium-2">premium-2 (250MB)</option>
            <option value="premium-3">premium-3 (500MB)</option>
            <option value="premium-5">premium-5 (1GB)</option>
          </select>
          <p
            className={`dyno-value ${
              redisAvailable ? "redis-available" : "redis-processing"
            }`}
          >
            {redisStatus}
          </p>
        </div>
      </div>
      <div className="events-panel">
        <div className="events-header">
          <h2>Existing Events</h2>
          <button className="refresh-button" onClick={handleRefresh}>
            <FaSync />
          </button>
        </div>
        <div className="scrollable-panel" ref={scrollablePanelRef}>
          <ul className="events-list">
            {events.map((event, index) => (
              <li
                key={index}
                onClick={() => handleEventClick(event)}
                className={selectedEvent === event ? "selected" : ""}
              >
                <div className="event-details">
                  <span className="event-name">{event.name}</span>
                  <span className="event-date">
                    {event.date.toLocaleDateString("en-GB")}
                  </span>
                </div>
                <span
                  className={`status-indicator ${
                    isEventReady(event) ? "green" : "red"
                  }`}
                ></span>
                <span
                  className={`work-indicator ${
                    isEventInProgress(event) ? isEventInProgress(event) : ""
                  }`}
                ></span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="create-event-panel">
        <h2>Create New Event</h2>
        <form onSubmit={handleCreateEvent}>
          <input
            type="text"
            name="eventName"
            placeholder="Event Name"
            className="input-centered"
            value={newEvent.eventName}
            onChange={handleInputChange}
            required
          />
          <DatePicker
            selected={newEvent.startTime}
            onChange={(date) => handleDateChange(date, "startTime")}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="dd/MM/yyyy HH:mm"
            placeholderText="Start of the event"
            className="date-picker-input"
          />
          <DatePicker
            selected={newEvent.endTime}
            onChange={(date) => handleDateChange(date, "endTime")}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="dd/MM/yyyy HH:mm"
            placeholderText="End of the event"
            className="date-picker-input"
            minDate={newEvent.startTime}
            disabled={!newEvent.startTime}
          />
          <div className="toggle-switch">
            <label>
              Reusable Heads
              <input
                type="checkbox"
                checked={newEvent.reusableHeads}
                onChange={toggleReusableHeads}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <button type="submit">Create Event</button>
        </form>
      </div>
      <div className="resize-images-panel">
        <h2>Resize Body Images</h2>
        <div className="resize-input-button-container">
          <input
            type="number"
            placeholder="Enter height"
            value={targetHeight}
            onChange={handleHeightChange}
            onBlur={handleHeightBlur} // Validate when the user leaves the input field
            className="resize-input"
          />
          <button
            onClick={handleResizeImages}
            className="resize-button"
            disabled={isButtonDisabled}
          >
            Send
          </button>
        </div>
      </div>
      {selectedEvent && (
        <EventPopup
          event={selectedEvent}
          onClose={handleClosePopup}
          loadEvents={loadEvents}
        />
      )}
    </div>
  );
};

export default AdminPanel;
