/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../generalUtils";
import "./FaceGallery.css";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";
import { useAppContext } from "../../AppContext";
import { ToastContainer } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getFaceImageFromDB,
  updateLastUpdateTime,
  getLastUpdateTime,
  saveFaceImageToDB,
  getAllKeysFromDB,
  deleteImageFromDB,
} from "../../IndexedDBUtils";

const FACE_STORE_NAME = "FaceImages";
const FACE_REFRESH_INTERVAL_SECONDS = 10;

const FaceGallery = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [imagePaths, setImagePaths] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const number = parseInt(sessionStorage.getItem("number")) || 1;
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { eventName } = useAppContext();

  useEffect(() => {
    const loadImages = async () => {
      const lastUpdateTime = await getLastUpdateTime(FACE_STORE_NAME);
      const currentTime = new Date();
      const timeDifference = (currentTime - new Date(lastUpdateTime)) / 1000;
      if (timeDifference < FACE_REFRESH_INTERVAL_SECONDS) {
        console.log("Loading images from IndexedDB...");
        await loadImagesFromDB();
      } else {
        console.log("Checking for updates from server...");
        await fetchDataFromServer();
      }
    };

    const loadImagesFromDB = async () => {
      const dbImages = [];
      const allKeys = await getAllKeysFromDB(FACE_STORE_NAME);

      for (const path of allKeys) {
        const image = await getFaceImageFromDB(path);
        if (image) {
          dbImages.push({ path, image });
        }
      }
      setImagePaths(dbImages);

      if (dbImages.length === 0) {
        console.log("No images found in IndexedDB, fetching from server...");
        fetchDataFromServer();
      }
      hideLoading();
    };

    const fetchDataFromServer = async () => {
      if (eventName) {
        const encodedEventName = encodeURIComponent(eventName);
        showLoading();
        try {
          const data = await fetchData(
            serverUrl,
            "/api/faces",
            encodedEventName
          );
          console.log("Paths fetched from server:", data);

          const keysFromDB = await getAllKeysFromDB(FACE_STORE_NAME);

          // Find new paths to add
          const newPaths = data.filter((path) => !keysFromDB.includes(path));

          // Find paths to delete
          const pathsToDelete = keysFromDB.filter(
            (path) => !data.includes(path)
          );

          if (newPaths.length === 0 && pathsToDelete.length === 0) {
            console.log("No changes in paths, skipping server fetch.");
            await loadImagesFromDB();
            hideLoading();
            updateLastUpdateTime(FACE_STORE_NAME);
            return;
          }

          // Delete images that no longer exist on the server
          const deletePromises = pathsToDelete.map(async (path) => {
            await deleteImageFromDB(FACE_STORE_NAME, path);
            console.log(`Image deleted from IndexedDB: ${path}`);
          });

          await Promise.all(deletePromises);

          console.log("New paths found, fetching images from server...");
          const imagePromises = newPaths.map(async (path) => {
            const response = await fetch(`${serverUrl}/image${path}`);
            if (!response.ok) {
              console.error(`Failed to fetch image for path: ${path}`);
              return;
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
              const base64Data = reader.result;
              await saveFaceImageToDB(path, base64Data);
              console.log(`Image saved to IndexedDB: ${path}`);
            };
          });

          await Promise.all(imagePromises);

          // Update imagePaths with all paths from server
          setImagePaths(
            data.map((path) => ({ path, image: `${serverUrl}/image${path}` }))
          );

          // Remove selected images that no longer exist
          const updatedSelectedImages = selectedImages.filter((img) =>
            data.includes(img)
          );
          setSelectedImages(updatedSelectedImages);
        } catch (error) {
          console.error("Error fetching faces:", error);
        } finally {
          hideLoading();
        }
      } else {
        console.error("FaceGallery - eventName is missing.");
      }
    };

    loadImages();
    hideLoading();

    const intervalId = setInterval(() => {
      loadImages();
    }, FACE_REFRESH_INTERVAL_SECONDS * 1000);

    return () => clearInterval(intervalId);
  }, [serverUrl, eventName, selectedImages]);

  const toggleImageSelection = (imagePath) => {
    if (selectedImages.includes(imagePath)) {
      setSelectedImages(selectedImages.filter((img) => img !== imagePath));
    } else {
      if (selectedImages.length < number) {
        setSelectedImages([...selectedImages, imagePath]);
      }
    }
  };

  const goToBodyGallery = async () => {
    sessionStorage.setItem("selectedFaces", JSON.stringify(selectedImages));
    showLoading();
    navigate(`/${eventName}/body-gallery`);
  };

  const goBack = async () => {
    showLoading();
    navigate(`/${eventName}`);
  };

  return (
    <div className="face-gallery-container">
      <ToastContainer />
      <h1 className="face-gallery-title rtl">בחרו את הראשים שלכם</h1>
      <div className="face-gallery-grid">
        {imagePaths.map(({ path, image }, index) => {
          const isSelected = selectedImages.includes(path);
          const isSelectable = selectedImages.length < number || isSelected;

          return (
            <div
              key={index}
              className={`face-gallery-image-container ${
                isSelected ? "face-gallery-image-selected" : ""
              } ${!isSelectable ? "face-gallery-image-unselectable" : ""}`}
              onClick={() => isSelectable && toggleImageSelection(path)}
            >
              <LazyLoadImage
                src={image}
                alt={`Face ${index}`}
                className="face-gallery-image"
                effect="blur"
              />
              {isSelected && (
                <div className="face-gallery-image-overlay">
                  <span className="text-white text-2xl">✔</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="face-gallery-button-container">
        <button className="face-gallery-button rtl" onClick={goBack}>
          חזרה
        </button>
        <button
          className={`face-gallery-button rtl ${
            selectedImages.length === number ? "" : "disabled-button"
          }`}
          onClick={goToBodyGallery}
          disabled={selectedImages.length !== number}
        >
          <span>הבא </span>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "normal",
            }}
          >
            ({selectedImages.length}/{number})
          </span>
        </button>
      </div>
    </div>
  );
};

export default FaceGallery;
