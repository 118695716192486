import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./GuideBeforeEdit.css";
import { useAppContext } from "../../AppContext";
import { ToastContainer } from "react-toastify";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";

import gifType0 from "../../assets/gifs/guide_before_edit-0.gif";
import gifType1 from "../../assets/gifs/guide_before_edit-1.gif";

const GuideBeforeEdit = () => {
  const [isGifComplete, setIsGifComplete] = useState(false);
  const [gifUrl, setGifUrl] = useState("");
  const navigate = useNavigate();
  const { eventName } = useAppContext();
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    const selectedBodies = JSON.parse(sessionStorage.getItem("selectedBodies")) || [];
    const bodiesCount = selectedBodies.length;
    const gifType = bodiesCount > 1 ? gifType1 : gifType0;

    setGifUrl(gifType);
    hideLoading();
    const timer = setTimeout(
      () => {
        setIsGifComplete(true);
      },
      gifType === gifType0 ? 4500 : 8000
    );

    return () => clearTimeout(timer);
  }, [eventName, hideLoading]); 

  const handleContinue = () => {
    showLoading();
    navigate(`/${eventName}/edit-your-character`);
  };

  return (
    <div className="guide-edit-page-container">
      <ToastContainer />
      <div className="guide-edit-container">
        {gifUrl && <img src={gifUrl} alt="Instruction GIF" className="gif" />}
      </div>
      <div className="button-container">
        <button
          className="continue-button"
          onClick={handleContinue}
          disabled={!isGifComplete}
        >
          המשך
        </button>
      </div>
    </div>
  );
};

export default GuideBeforeEdit;
