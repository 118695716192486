import React, { useState } from "react";
import { useLoading } from "../../Components/LoadingSpinner/LoadingContext";
import { parseDateString } from "./utils";
import "./EventPopup.css";
import { FaPencilAlt, FaSave } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EventPopup = ({ event, onClose, loadEvents }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { showLoading, hideLoading } = useLoading();

  const [isEditing, setIsEditing] = useState({
    name: false,
    duration: false,
    reusable_heads: false,
  });
  const [editedEvent, setEditedEvent] = useState({
    name: event.name,
    startTime: parseDateString(event.startTimeFormatted),
    endTime: parseDateString(event.endTimeFormatted),
    reusable_heads: event.reusable_heads,
  });

  const handleEdit = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleSave = async (field) => {
    showLoading();
    try {
      let new_value = "";
      if (field === "duration") {
        new_value = {
          startTime: editedEvent.startTime.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: editedEvent.endTime.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
      } else if (field === "reusable_heads") {
        new_value = editedEvent.reusable_heads ? "Yes" : "No";
      } else {
        new_value = editedEvent[field];
      }
      const response = await fetch(`${serverUrl}/api/update-event-field`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_name: event.name,
          event_date: event.date.toLocaleDateString("en-GB"),
          field: field,
          new_value: new_value,
        }),
      });

      if (response.ok) {
        const updatedEvent = { ...event, ...editedEvent };
        setEditedEvent(updatedEvent);
        setIsEditing((prev) => ({ ...prev, [field]: false }));

        await loadEvents();

        if (field === "name" || field === "duration") {
          onClose();
        }
      } else {
        console.error("Failed to update event");
      }
    } catch (error) {
      console.error("Error updating event:", error);
    } finally {
      hideLoading();
    }
  };

  const handleNameChange = (e) => {
    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9&]/g, "");
    setEditedEvent({ ...editedEvent, name: filteredValue });
  };

  const handleDateChange = (field, date) => {
    if (
      field === "startTime" &&
      editedEvent.endTime &&
      date >= editedEvent.endTime
    ) {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
        endTime: null,
      }));
    } else {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [field]: date,
      }));
    }
  };

  const handleReusableHeadsChange = (e) => {
    setEditedEvent({
      ...editedEvent,
      reusable_heads: e.target.value === "Yes",
    });
  };

  if (!event) return null;

  const issues = event.issues || [];

  const getStatusStyle = (status) => {
    switch (status) {
      case "Upcoming":
        return "status-upcoming";
      case "Ongoing":
        return "status-ongoing";
      case "Completed":
        return "status-completed";
      default:
        return "";
    }
  };

  const mapStatusWord = (status) => {
    switch (status) {
      case "waiting":
        return "Upcoming";
      case "running":
        return "Ongoing";
      case "pass":
        return "Completed";
      default:
        return "N/A";
    }
  };

  const mappedStatus = mapStatusWord(event.inProgress);

  const handleDelete = async () => {
    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/delete-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventName: event.name,
          eventDate: event.date.toLocaleDateString("en-GB"),
        }),
      });
      if (response.ok) {
        onClose();
      } else {
        hideLoading();
        console.error("Failed to delete event");
      }
    } catch (error) {
      hideLoading();
      console.error("Error deleting event:", error);
    }
    hideLoading();
  };

  const handleArchive = async () => {
    try {
      showLoading();
      const response = await fetch(`${serverUrl}/api/archive-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventName: event.name,
          eventDate: event.date.toLocaleDateString("en-GB"),
        }),
      });
      if (response.ok) {
        onClose();
      } else {
        hideLoading();
        console.error("Failed to archive event");
      }
    } catch (error) {
      hideLoading();
      console.error("Error archiving event:", error);
    }
    hideLoading();
  };

  return (
    <div className="popup-overlay ltr">
      <div className="popup-content ltr">
        <button className="close-btn" onClick={onClose}>
          X
        </button>
        <h2 className="popup-title">Event Details</h2>
        <p>
          <strong>Name:</strong>{" "}
          {isEditing.name ? (
            <>
              <input
                type="text"
                value={editedEvent.name}
                onChange={handleNameChange}
                className="input-editing"
              />
              <FaSave
                className="save-icon"
                onClick={() => handleSave("name")}
              />
            </>
          ) : (
            <>
              {editedEvent.name}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("name")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Event Duration:</strong>{" "}
          {isEditing.duration ? (
            <>
              <div className="date-picker-container">
                <DatePicker
                  selected={editedEvent.startTime}
                  onChange={(date) => handleDateChange("startTime", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="input-editing"
                />
                {" - "}
                <DatePicker
                  selected={editedEvent.endTime}
                  onChange={(date) => handleDateChange("endTime", date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="input-editing"
                  minDate={editedEvent.startTime}
                  disabled={!editedEvent.startTime}
                />
              </div>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("duration")}
              />
            </>
          ) : (
            <>
              {editedEvent.startTime.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {editedEvent.endTime.toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("duration")}
                />
              </span>
            </>
          )}
        </p>
        <p className={getStatusStyle(mappedStatus)}>
          <strong>In Progress:</strong> {mappedStatus}
        </p>
        <p>
          <strong>Bodies:</strong> {event.png_count} images, {event.bodieSize}MB
        </p>
        <p>
          <strong>Face Reused:</strong>{" "}
          {isEditing.reusable_heads ? (
            <>
              <select
                value={editedEvent.reusable_heads ? "Yes" : "No"}
                onChange={handleReusableHeadsChange}
                className="input-editing"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <FaSave
                className="save-icon"
                onClick={() => handleSave("reusable_heads")}
              />
            </>
          ) : (
            <>
              {editedEvent.reusable_heads ? "Yes" : "No"}
              <span>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEdit("reusable_heads")}
                />
              </span>
            </>
          )}
        </p>
        <p>
          <strong>Event Link:</strong>{" "}
          <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
            עבור לאתר
          </a>
        </p>
        <p className={event.ready ? "status-ready" : "status-not-ready"}>
          <strong>Status:</strong> {event.ready ? "Ready" : "Not Ready"}
        </p>
        {!event.ready && issues.length > 0 && (
          <div>
            <h3 className="issues-title ltr">Issues:</h3>
            <ul className="issues-list">
              {issues.map((issue, index) => (
                <li key={index} dir="ltr">
                  {issue}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="button-group">
          <button className="delete-btn" onClick={handleDelete}>
            Delete Event
          </button>
          <button className="archive-btn" onClick={handleArchive}>
            Archive Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventPopup;
