/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import FaceGallery from "./Pages/FaceGallery/FaceGallery";
import BodyGallery from "./Pages/BodyGallery/BodyGallery";
import GuideBeforeEdit from "./Pages/GuideBeforeEdit/GuideBeforeEdit";
import EditYourCharacter from "./Pages/EditYourCharacter/EditYourCharacter";
import Result from "./Pages/Result/Result";
import {
  LoadingProvider,
  useLoading,
} from "./Components/LoadingSpinner/LoadingContext";
import LoadingSpinner from "./Components/LoadingSpinner/LoadingSpinner";
import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import WhatsAppButton from "./Components/WhatsAppButton/WhatsAppButton";
import EventNotFound from "./Pages/EventNotFound/EventNotFound";
import EventStatusPage from "./Pages/EventStatusPage/EventStatusPage";
import { fetchData } from "./generalUtils";
import { deleteDatabase } from "./IndexedDBUtils";
import Login from "./Login";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const App = () => {
  return (
    <Router>
      <LoadingProvider>
        <EnsureHomeOnReload>
          <Routes>
            <Route
              exact
              path="/:eventName"
              element={<EventWrapper Component={Home} />}
            />
            <Route
              path="/:eventName/face-gallery"
              element={<EventWrapper Component={FaceGallery} />}
            />
            <Route
              path="/:eventName/body-gallery"
              element={<EventWrapper Component={BodyGallery} />}
            />
            <Route
              path="/:eventName/guide-before-edit"
              element={<EventWrapper Component={GuideBeforeEdit} />}
            />
            <Route
              path="/:eventName/edit-your-character"
              element={<EventWrapper Component={EditYourCharacter} />}
            />
            <Route
              path="/:eventName/result"
              element={<EventWrapper Component={Result} />}
            />
            <Route
              path="/:eventName/status"
              element={<EventWrapper Component={EventStatusPage} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<AdminPanel />} />
            <Route path="*" element={<EventNotFound />} />
          </Routes>
          {window.location.pathname !== "/" && <WhatsAppButton />}
        </EnsureHomeOnReload>
        <LoadingHandler />
      </LoadingProvider>
    </Router>
  );
};

const EventWrapper = ({ Component }) => {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const eventName = window.location.pathname.split("/")[1];
    const encodedEventName = encodeURIComponent(eventName);
    console.log("eventName: ", encodedEventName);
    if (encodedEventName) {
      sessionStorage.setItem("event_name", encodedEventName);
    }
    const checkEventExists = async () => {
      try {
        const data = await fetchData(
          serverUrl,
          "/api/check-event",
          encodedEventName
        );
        setEventData(data);
      } catch (error) {
        console.log("Error checking event:", error);
        setEventData({ exists: false });
      }
    };

    checkEventExists();
  }, []);

  if (!eventData) {
    return <LoadingSpinner />;
  }

  if (!eventData.exists) {
    deleteDatabase("ImageDatabase");
    return <EventNotFound />;
  }

  if (eventData.progressStatus !== "running") {
    deleteDatabase("ImageDatabase");
    return (
      <EventStatusPage
        status={eventData.progressStatus}
        startTime={eventData.startTime}
      />
    );
  }

  return <Component />;
};

const EnsureHomeOnReload = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === "reload"
    ) {
      if (location.pathname !== "/") {
        const eventName = location.pathname.split("/")[1];
        navigate(`/${eventName}`);
      }
    }
  }, []);

  return children;
};

const LoadingHandler = () => {
  const { isLoading } = useLoading();
  return isLoading ? <LoadingSpinner /> : null;
};

export default App;
