import { getImageAspectRatio } from "./utils";
import { getFaceImageFromDB, getBodyImageFromDB } from "../../IndexedDBUtils";

export const loadImages = async (
  stageRef,
  setImages,
  showLoading,
  hideLoading,
  setIsLoadingComplete,
  navigate,
  showAlert
) => {
  showLoading();
  const bodies = JSON.parse(sessionStorage.getItem("selectedBodies")) || [];
  const faces = JSON.parse(sessionStorage.getItem("selectedFaces")) || [];

  const stage = stageRef.current;
  const canvasHeight = stage.height();
  const canvasWidthLimit = window.innerWidth;
  let finalBodyHeight = canvasHeight * 0.975;

  let totalBodiesWidth = 0;
  let totalFacesWidth = 0;
  const bodyWidths = [];
  const faceWidths = [];

  const allImages = await Promise.all(
    bodies.concat(faces).map(async (imagePath, index) => {
      const isBody = bodies.includes(imagePath);
      const isFace = faces.includes(imagePath);
      let height;
      let width;
      try {
        const imageData = isBody
          ? await getBodyImageFromDB(imagePath)
          : await getFaceImageFromDB(imagePath);

        if (!imageData) {
          throw new Error(`Image not found in IndexedDB: ${imagePath}`);
        }

        const dimensions = await getImageAspectRatio(imageData);
        const aspectRatio = dimensions.aspectRatio;
        const imgHeight = dimensions.height;

        if (isBody) {
          height = finalBodyHeight;
          width = height * aspectRatio;
          bodyWidths[index] = width;
          totalBodiesWidth += width;
        } else if (isFace) {
          const fileName = imagePath.split("/").pop();
          const faceHeight = parseInt(fileName.split("+")[1].split(".")[0]);
          const requiredFaceH = (finalBodyHeight * (13 / 16)) / 5.25; // ratio of 5.25 between the face and the body
          const sf = requiredFaceH / faceHeight;

          height = imgHeight * sf;
          width = height * aspectRatio;
          faceWidths.push(width);
          totalFacesWidth += width;
        }

        return {
          src: imageData,
          width: width,
          height: height,
          aspectRatio: aspectRatio,
          flipped: false,
          originalSrc: imagePath,
          position: { x: 0, y: 0 },
          draggable: !isBody,
        };
      } catch (error) {
        console.error("Error loading image:", error);
        const eventName = sessionStorage.getItem("event_name");
        const message = "נראה שתמונה זו כבר הייתה בשימוש, אנא נסו שנית";
        showLoading();
        showAlert("err", message, 10000);
        navigate(`/${eventName}`);
        return null;
      }
    })
  );

  const validImages = allImages.filter((image) => image !== null);

  const totalBodiesWidthReq = totalBodiesWidth * 1.1;
  let canvasWidth = Math.min(totalBodiesWidthReq, canvasWidthLimit);

  let scaleFactor = 1;
  if (totalBodiesWidthReq > canvasWidthLimit) {
    scaleFactor *= canvasWidthLimit / totalBodiesWidthReq;
    finalBodyHeight *= scaleFactor;
    bodyWidths.forEach((width, index) => {
      bodyWidths[index] = width * scaleFactor;
    });
  }

  const totalBodyGaps = bodies.length;
  const bodyGapWidth =
    Math.abs(canvasWidth - totalBodiesWidth * scaleFactor) / totalBodyGaps;

  let currentX = bodyGapWidth / 2;
  const positionedBodies = validImages.map((image, index) => {
    if (bodies.includes(image.originalSrc)) {
      const width = bodyWidths[index];
      const height = width / image.aspectRatio;
      const newImage = {
        ...image,
        width: width,
        height: height,
        position: { x: currentX, y: canvasHeight - height },
      };
      currentX += width + bodyGapWidth;
      return newImage;
    }
    return image;
  });

  const totalFaceGaps = faces.length + 1;
  const faceGapWidth = (canvasWidth - totalFacesWidth) / totalFaceGaps;

  currentX = faceGapWidth;

  const positionedFaces = positionedBodies.map((image) => {
    if (faces.includes(image.originalSrc)) {
      const newImage = {
        ...image,
        width: image.width * scaleFactor,
        height: image.height * scaleFactor,
        position: { x: currentX, y: 0 },
      };
      currentX += newImage.width + faceGapWidth;
      return newImage;
    }
    return image;
  });

  setImages(positionedFaces);
  hideLoading();
  setIsLoadingComplete(true);
  return canvasWidth;
};

export const handleSaveAndFinish = async (
  stageRef,
  setActiveImage,
  navigate,
  eventName
) => {
  if (stageRef.current) {
    setActiveImage(null);

    setTimeout(async () => {
      let dataUrl = null;

      for (let pixelRatio = 3; pixelRatio >= 1; pixelRatio -= 0.1) {
        try {
          dataUrl = stageRef.current.toDataURL({
            pixelRatio: pixelRatio,
            imageSmoothingEnabled: true,
          });
          if (dataUrl.length < 2.75 * 1024 * 1024) {
            break;
          }
        } catch (e) {
          console.error("Error generating image:", e);
        }
      }

      if (dataUrl) {
        localStorage.setItem("finalImage", dataUrl);
        navigate(`/${eventName}/result`);
        return;
      }
    }, 1000);
  }
};
